<template>
    <div class="create-employee-main">
        <h3 class="create-employee-main__title">Основные</h3>
        <VCustomInput
            placeholder="ФИО*"
            :error="v$.name.$error"
            v-model="form.name"
            :maxlength="60"
            class="create-employee-main__name"/>
        <p class="create-employee-main__inp-error" v-if="v$.name.$error">Заполните обязательное поле</p>
        <div class="create-employee-main__row">
            <div class="create-employee-main__inp-wrapper">
                <VCustomInput
                    placeholder="E-mail*"
                    v-model="form.email"
                    :error="v$.email.$error || (!!errorEmailText)"
                    :disabled="editing"
                    class="create-employee-main__inp">
                    <template #icon v-if="editing">
                        <img src="@/assets/images/icons/lock.svg" alt="tooltip">
                    </template>
                </VCustomInput>
                <div class="create-employee-main__inp-errors" v-if="v$.email.$error">
                    <p class="create-employee-main__inp-error" v-if="v$.email.$errors[0].$validator === 'required'">
                        Заполните обязательное поле</p>
                    <p class="create-employee-main__inp-error" v-if="v$.email.$errors[0].$validator === 'email'">Введите
                        правильный Email</p>
                </div>
                <p class="create-employee-main__inp-error" v-if="errorEmailText">{{ errorEmailText }}</p>
            </div>

            <div class="create-employee-main__inp-wrapper">
                <div class="create-employee-main__inp-fake" v-if="editing && !editPassword">
                    <div class="create-employee-main__inp-fake-left">
                        <p class="create-employee-main__inp-fake-text">Пароль*</p>
                        <div class="create-employee-main__inp-fake-dots">
                            <span class="create-employee-main__inp-fake-dot" v-for="i in 8" :key="i"></span>
                        </div>
                    </div>
                    <div class="create-employee-main__inp-fake-btn" @click="showPassword">Изменить</div>
                </div>
                <VCustomInput
                    v-if="!editing || editPassword"
                    placeholder="Пароль*"
                    v-model="form.password"
                    :error="v$.password.$error"
                    type="password"
                    class="create-employee-main__inp">
                    <template #icon>
                        <VTooltip text="Используйте не менее 8 символов" autoWidth class="create-employee-main__tooltip">
                            <template #icon>
                                <img src="@/assets/images/icons/tooltip-gray-fill.svg" alt="tooltip">
                            </template>
                        </VTooltip>
                    </template>
                </VCustomInput>

                <div class="create-employee-main__inp-errors" v-if="v$.password.$error">
                    <p class="create-employee-main__inp-error" v-if="v$.password.$errors[0].$validator === 'requiredPassword'">
                        Заполните обязательное поле</p>
                    <p class="create-employee-main__inp-error" v-if="v$.password.$errors[0].$validator === 'minLength'">
                        Используйте не менее 8 символов</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, computed} from 'vue'
import useVuelidate from "@vuelidate/core";
import {required, email, minLength} from "@vuelidate/validators";
import {useRoute} from "vue-router";

const route = useRoute();

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    },
    validation: {
        type: Boolean,
        default: false
    },
    editing: {
        type: Boolean,
        default: false
    },
    errorEmailText: {
        type: String
    }
});

const emit = defineEmits(["update:modelValue", "editPassword"]);

const form = computed({
    get: () => props.modelValue,
    set: (value) => emit("update:modelValue", value),
});

const requiredPassword = (val) => {
    if (props.editing && !editPassword.value) return true
    else return !!val
}

const rules = computed(() => ({
    name: {required},
    email: {required, email},
    password: {requiredPassword, minLength: minLength(8)},
}));
const v$ = useVuelidate(rules, form);

const editPassword = ref(false)

const showPassword = () => {
    editPassword.value = true
    emit('editPassword')
}
</script>

<style scoped lang="scss">
.create-employee-main {
    width: 100%;
    padding: 24px;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 2px 2px 8px 0px #0000000A;

    &__title {
        font-size: 20px;
        font-weight: 500;
    }

    &__name {
        width: 100%;
        margin-top: 16px;
    }

    &__row {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
    }

    &__inp-wrapper {
        width: 49%;
    }

    &__inp-fake {
        width: 100%;
        height: 48px;
        padding: 6px 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $light;
    }

    &__inp-fake-left {
        height: 100%;
    }

    &__inp-fake-dots {
        margin-top: 8px;
        display: flex;
        align-items: center;
    }

    &__inp-fake-dot {
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: #000;

        &:not(:first-child) {
            margin-left: 8px;
        }
    }

    &__inp-fake-text {
        font-size: 12px;
        color: $gray;
    }

    &__inp-fake-btn {
        font-size: 14px;
        color: $blue;
        user-select: none;
        cursor: pointer;
        transition: .3s;

        &:hover {
            color: #074CA7;
        }

        &:active {
            color: #0A3489;
        }
    }

    &__inp {
        width: 100%;
    }

    &__inp-error {
        margin-top: 4px;
        font-size: 14px;
        color: $red;
    }

    &__tooltip {
        &::v-deep(.v-tooltip__icon) {
            margin-top: 3px;
        }

        &::v-deep(.v-tooltip__message) {
            margin-top: 9px;
            margin-right: 4px;
        }
    }
}
</style>
