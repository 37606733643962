<template>
    <div class="create-employee-settings">
        <h3 class="create-employee-settings__title">Настройки</h3>

        <VSelect
            class="create-employee-settings__select"
            placeholder="Тип сотрудника*"
            v-if="!admin && role === 'SUPER_ADMIN'"
            :options="optionsAdmin"
            :error="(!form.employeeType && validation) || (!!errorText)"
            :disabled="editing"
            v-model="form.employeeType"/>

        <VSelect
            class="create-employee-settings__select"
            placeholder="Тип сотрудника*"
            v-if="!admin && role === 'MAIN_HR'"
            :options="optionsMainHr"
            :error="(!form.employeeType && validation) || (!!errorText)"
            :disabled="editing"
            v-model="form.employeeType"/>

        <p class="create-employee-settings__error-text" v-if="!form.employeeType && validation">Заполните обязательное
            поле</p>
        <p class="create-employee-settings__error-text" v-if="errorText">{{ errorText }}</p>
        <VCustomInput
            v-if="form.employeeType === 'APPROVING_PERSON'"
            placeholder="Должность согласованта*"
            :error="(!form.jobTitle && validation) || (!!errorText) || (!!form.jobTitle && form.jobTitle?.trim()?.length === 0)"
            v-model="form.jobTitle"
            class="create-employee-settings__job-title"/>
        <p class="create-employee-settings__error-text" v-if="form.employeeType === 'APPROVING_PERSON' && !form.jobTitle && validation || (!!form.jobTitle && form.jobTitle?.trim()?.length === 0)">Заполните обязательное
            поле</p>

        <div class="create-employee-settings__user-access" v-if="!hideUserAccess">
            <p class="create-employee-settings__user-access-title">Доступ пользователя</p>
            <div class="create-employee-settings__user-access-blocks">
                <div
                    class="create-employee-settings__user-access-block"
                    :class="{'create-employee-settings__user-access-block_active': !form.access}"
                    @click="form.access = false">Активировать
                </div>
                <div
                    class="create-employee-settings__user-access-block"
                    :class="{'create-employee-settings__user-access-block_active': form.access}"
                    @click="form.access = true">Заблокировать
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed} from 'vue'
import {useRoute} from "vue-router";
import {useStore} from "vuex";

const route = useRoute();
const store = useStore();

const role = store.getters["auth/role"];

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    },
    validation: {
        type: Boolean,
        default: false
    },
    errorText: {
        type: String,
    },
    admin: {
        type: Boolean,
        default: false
    },
    hideUserAccess: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(["update:modelValue"]);

const form = computed({
    get: () => props.modelValue,
    set: (value) => emit("update:modelValue", value),
});

const editing = !!route.params.employeeId

const optionsAdmin = [
    {
        id: 'MAIN_HR',
        label: 'Главный HR-Менеджер'
    },
    {
        id: 'HR',
        label: 'HR-Менеджер'
    },
    {
        id: 'APPROVING_PERSON',
        label: 'Согласовант'
    },
]
const optionsMainHr = [
    {
        id: 'HR',
        label: 'HR-Менеджер'
    },
    {
        id: 'APPROVING_PERSON',
        label: 'Согласовант'
    }
]

if (editing) {
    optionsMainHr.push({
        id: 'MAIN_HR',
        label: 'Главный HR-Менеджер'
    },)
}
</script>

<style scoped lang="scss">
.create-employee-settings {
    position: relative;
    width: 100%;
    padding: 24px;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 2px 2px 8px 0px #0000000A;


    &__title {
        font-size: 20px;
        font-weight: 500;
    }

    &__select {
        width: 100%;
        margin-top: 16px;
    }

    &__job-title {
        width: 100%;
        margin-top: 16px;
    }

    &__user-access {
        width: 100%;
        height: 48px;
        padding: 0 4px 0 16px;
        margin-top: 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $light;
    }

    &__user-access-blocks {
        height: 40px;
        padding: 4px;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        background-color: $light-gray-1;
    }

    &__user-access-block {
        height: 100%;
        padding: 0 14px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $gray;
        font-size: 14px;
        cursor: pointer;
        user-select: none;

        &_active {
            background-color: #fff;
            color: $dark;
        }
    }

    &__user-access-title {
        font-size: 14px;
    }

    &__error-text {
        margin-top: 4px;
        font-size: 14px;
        color: $red;
    }
}
</style>
